import React from "react"
import { StaticImage as Img } from "gatsby-plugin-image"
import Content from "./style"
import { SuperTag } from "~components"
import { Col, Container, Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"

export default function ContentSectionOne() {
  return (
    <Content id="rentas">
      <Content.SectionShape></Content.SectionShape>
      <Container>
        <Content.Inner>
          <Row className="align-items-center justify-content-center justify-content-lg-start">
            <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-2 text-right">
              <Fade right>
                <Content.TextBlock>
                  <Content.Title as="h2">
                    {" "}
                    <SuperTag value="Rentas" />{" "}
                  </Content.Title>

                  <Content.Text>
                    {" "}
                    <SuperTag
                      value="¿Quieres rentar un departamento <br className='d-none d-xs-block' /> u oficina en Park Pedregal?
                    <br className='d-none d-xs-block' /><br className='d-none d-xs-block' />Llama al <a href='tel:5556040565' id='telrenta'>55 5604 0565</a>
                    <br className='d-none d-xs-block' /><br className='d-none d-xs-block' />
                    ¿Necesitas rentar el departamento<br className='d-none d-xs-block' />u oficina que compraste en Park Pedregal?
                    <br className='d-none d-xs-block' /><br className='d-none d-xs-block' />
                    Contacta a tu asesor inmobiliario<br className='d-none d-xs-block' /> o llama al <a href='tel:5556040565' id='telrenta'>55 5604 0565</a>
                    "
                    />{" "}
                  </Content.Text>
                </Content.TextBlock>
              </Fade>
            </Col>
            <Col
              xs="12"
              className="col-xl-6 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-1 "
            >
              <Fade left>
                <Content.Image>
                  <Img
                    className="w-100 fachadafinal"
                    src="../../assets/image/desarrollo/fachadafinal.jpg"
                    alt="content"
                    layout="fullWidth"
                    placeholder="blurred"
                  />
                </Content.Image>
              </Fade>
            </Col>
          </Row>
        </Content.Inner>
      </Container>
    </Content>
  )
}
